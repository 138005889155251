.wrap {
  width: 100%;
}

.block {
  background: white;
  margin-left: 16px;
}

.buttonWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.toogle {
  margin-top: 4px;
}
