.flex {
  display: flex;
}

.flexDiv {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.containerDiv {
  width: 100%;
  height: 100%;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.capitalizeFirst {
  display: inline-block;
  text-transform: lowercase;
}

.capitalizeFirst::first-letter {
  text-transform: capitalize;
}

.sortOption {
  cursor: pointer;
  min-width: 200px;
  padding: 8px 16px;
}

.sortOption:hover,
.sortOption.active {
  background-color: #efeff2;
}

.emptyState {
  align-items: center;
  display: flex;
  text-align: center;
}

.emptyState > div {
  margin: 0 auto;
}

/* From TableFilters.module.css in voltron-table */
.filterControl {
  display: flex;
  align-items: center;
  height: 32px;
  border: 1px solid #d7d7da;
  border-radius: 2px;
  padding: 8px 16px;
  padding-right: 20px;
  cursor: pointer;
  transition: border 0.1s ease;
}

.cardProductTile {
  padding: 24px;
  border: 1px solid #dedfe6;
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-flow: column;
}

:global [class*='CardProductGrid'] [class*='active'] [class*='filtersSmall'] {
  overflow-y: visible;
  overflow-x: visible;
}
