.status {
  width: 8px;
  height: 8px;
  background: #ffaa2b;
  border-radius: 12px;
  display: inline-block;
  margin-right: 8px;
  padding: 0;
}

.status + div {
  padding: 0;
}

.status.active {
  background: #3db65c;
}
.status.sentForRevision {
  background: #ffc13b;
}
.status.pendingApproval {
  background: #16accd;
}
.status.rejected {
  background: #ff304a;
}
.status.draft {
  background: #cdced9;
}
