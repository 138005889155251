.tableWrap {
  position: relative;
}

.tableWrap [class^='Table-module__headerSizer'] {
  align-items: center;
}

.tableWrap [class^='Table-module__headerSizer'] [class^='Table-module__chevron'] {
  display: none;
  position: static;
  margin-left: 8px;
}

.tableWrap [class*='Table-module__show__'] [class^='Table-module__chevron'] {
  display: flex;
}
