.welcomeCardContainer {
  transition: opacity 0.5s, height 0.4s 0.1s;
  overflow: hidden;
  height: 264px;
}

.hidden {
  opacity: 0;
  height: 0;
}

.contentContainer {
  display: flex;
  border-radius: 4px;
  box-shadow: 0px 6px 8px rgba(25, 25, 25, 0.08), 0px 0px 3px rgba(25, 25, 25, 0.12),
    0px 0px 1px rgba(25, 25, 25, 0.2);
  padding: 24px;
  position: relative;
  margin-right: 3px;
  margin-top: 1px;
}

.iconContainer {
  margin-left: 4px;
  margin-right: 24px;
  margin-top: -1px;
}

.leftAccentLine {
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: #6256e9;
  border-radius: 4px 0 0 4px;
}

.cardTextWidth {
  width: 540px;
}
