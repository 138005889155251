.addButton {
  display: inline-flex;
  cursor: pointer;
}

.inputRightAddOnText {
  border-radius: 0 2px 2px 0;
  line-height: 34px;
  color: inherit;
  text-align: center;
  min-width: max-content;
  padding: 0 16px;
  transition: all 0.1s ease;
  transition-property: border, background-color;
  font-size: 13px;
  cursor: pointer;
}

.flexDivSpaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexCenter {
  display: flex;
  justify-content: center;
}
