.action {
  cursor: pointer;
  height: 42px;
  display: flex;
  padding: 8px 16px;
  font-size: 14px;
  background-color: #fff;
  transition: background-color 0.1s ease;
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  align-items: center;
}

.action:hover {
  background-color: #f8f9fa;
}
