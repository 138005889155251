/**
 * IMPORTANT: All styles in this sheet are related to older
 * versions of layout components and can ALL be removed when
 * the `cfn-beta` flag is removed and CFN components become
 * permanent.
 */
.wrap {
  width: 100%;
  overflow-x: hidden;
}

.page {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 120px;
  min-height: calc(100vh - 120px);
}

.content {
  margin: 24px;
  width: 100%;
}

/*
  Temporary classes:
  After GA complete, remove Unify Beta & Reskin Lite flag check.
  At this point the .reskinLite styles can be moved to .wrap, .page.,
  .content, or any other classes with style overrides.
*/

@media (max-width: 1712px) {
  .layoutContainer {
    margin-right: 16px;
  }
}

.wrapperForMargin {
  min-width: 1200px;
}

.layoutContainer {
  display: flex;
  justify-content: center;
}

.layoutContainer > div {
  display: flex;
  max-width: 1712px;
  min-width: 1184px;
  width: 100%;
}

.layoutContainer .wrap {
  max-width: 1440px;
  overflow: visible;
}

.layoutContainer .page {
  max-width: 1440px;
  margin-top: unset;
  min-height: calc(100vh - 72px);
  min-width: 912px;
  background: unset;
}

.layoutContainer .wrap.full-content-v2 .content,
.layoutContainer .content {
  display: inline-table;
  /* 16 to match right content margin */
  margin-bottom: 16px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
}

.layoutContainer .wrap.full-content-v2 .container,
.layoutContainer .container {
  background: #fff;
  border-radius: 8px;
  padding: 24px;
}

.layoutContainer .content.withLeftNav {
  margin-left: 264px;
}

.wrap.white {
  background: unset;
}
/* End of temporary Reskin Lite classes*/

.content.withLeftNav {
  margin-left: 288px;
}

.leftNav {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  height: calc(100vh - 120px);
  width: 264px;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 102;
}

.hideSmall {
  display: flex;
  align-items: center;
}

.wrap.full-content .content {
  margin: 0;
}

.wrap.full-content-v2 .content {
  margin: 32px 80px;
}

.wrap.white {
  background: #fff;
}

@media (max-width: 1200px) {
  .hideSmall {
    display: none;
  }
}
