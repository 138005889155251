.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.headerWidth {
  width: 400px;
}

.textWidth {
  width: 384px;
}
