.enabled :global div[class*='header'] {
  background-color: #fed330;
}

.flexDiv {
  display: flex;
  align-items: center;
}

.loadingBlock {
  width: 100%;
  height: 340px;
  border-radius: 2px;
}

.noItems {
  width: 100%;
  text-align: center;
  margin-top: 160px;
}

.listWrapper tr td:first-child {
  padding-right: 72px;
}
.listWrapper tr th:first-child {
  width: 1% !important;
}

.tokenItem {
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
