.heading {
  display: flex;
  align-items: center;
}

.status {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.dot {
  display: inline-flex;
  height: 8px;
  min-height: 8px;
  width: 8px;
  min-width: 8px;
  margin-right: 8px;
  margin-top: 2px;
  background-color: #fec23b;
  border-radius: 50%;
}
