.realTimeDecisioningModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  animation-duration: 0.75s;
  animation-name: slideup;
  background-color: white;
}

.header {
  background-color: white;
  padding: 8px 16px;
}

.flexText {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.iFrame {
  height: calc(100vh - 40px);
  width: 100%;
  background-color: #3b2ce3;
}

iframe {
  border: none;
}

@keyframes slideup {
  from {
    margin-top: 25vh;
    opacity: 0;
  }
  to {
    margin-top: 0vh;
    opacity: 1;
  }
}
