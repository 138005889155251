.wrap {
  width: 100%;
  overflow-x: hidden;
}

.header {
  width: 100%;
  z-index: 150;
  position: fixed;
  background-color: white;
}

/* Temporary: once GA occurs all reskinLite related classes should be merged in */
.header.reskinLite .headerMain,
.header.reskinLite {
  padding-left: 0;
  padding-right: 0;
}
.header.reskinLite {
  position: unset;
  background-color: unset;
  max-width: 1440px;
}
.header.reskinLite .headerMain {
  border-bottom: unset;
}

.headerMain {
  height: 72px;
  padding: 0 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #efeff2;
  padding-right: 24px;
}

.headerSub {
  background-color: white;
  padding: 0 40px;
  border-bottom: 1px solid #efeff2;
  height: 48px;
  display: flex;
  align-items: center;
  width: 100vw;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.headerSub::-webkit-scrollbar {
  display: none;
}

.headerLeft {
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 728px;
}

.programControl {
  display: flex;
  align-items: center;
}

.page {
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: calc(100vh - 120px);
  margin-top: 120px;
}

.content {
  margin: 24px;
  width: 100%;
}

.content.withLeftNav {
  margin-left: 280px;
}

.seperator {
  background-color: #dedfe6;
  width: 1px;
  height: 24px;
}

.headerRight {
  display: flex;
  align-items: center;
}

.leftNav {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  height: calc(100vh - 120px);
  width: 256px;
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
}

.hideSmall {
  display: flex;
  align-items: center;
}

/* Full Content styles */
.wrap.full-content .content {
  margin: 0;
}

.wrap.full-content-v2 .content {
  margin: 32px 80px;
}
