.wrap {
  position: relative;
  width: 100%;
}

.result {
  position: absolute;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 2px 0 rgba(22, 22, 26, 0.1), 0 4px 8px 0 rgba(22, 22, 26, 0.08);
  min-width: 100%;
  margin-top: 4px;
  width: max-content;
  z-index: 1;
  outline: none;
}

.resultBody {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resultText {
  align-items: center;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  height: 60px;
}

.noResults {
  align-items: center;
  padding: 8px;
  cursor: default;
  width: 100%;
  height: 40px;
}

.resultText.noHover {
  cursor: default;
}

.resultText:not(.noHover):hover {
  background: #e9f0fe;
}

.clear {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}

.resultSeperator {
  background-color: #dfe0e1;
  width: 1px;
  height: 16px;
}

.globalSearchContainer {
  display: flex;
}

.programDetails {
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  display: flex;
}
