/*******************
  Following the shorthand margin and padding from Bootstrap where the format is:
  {property}{sides}-{size}

  y - top and bottom
  x - left and right
  t - top
  b - bottom
  l - left
  r - right
*********************/

/**************** 
  PADDING SPACING
*****************/

.py-1 {
  padding: 8px 0px;
}

.py-2 {
  padding: 16px 0px;
}

.py-3 {
  padding: 24px 0px;
}

.py-4 {
  padding: 32px 0px;
}

.px-1 {
  padding: 0px 8px;
}

.px-2 {
  padding: 0px 16px;
}

.px-3 {
  padding: 0px 24px;
}

.px-4 {
  padding: 0px 24px;
}

.pt-1 {
  padding-top: 8px;
}

.pt-2 {
  padding-top: 16px;
}

.pt-3 {
  padding-top: 24px;
}

.pt-4 {
  padding-top: 32px;
}

.pb-1 {
  padding-bottom: 8px;
}

.pb-2 {
  padding-bottom: 16px;
}

.pb-3 {
  padding-bottom: 24px;
}

.pb-4 {
  padding-bottom: 32px;
}

.pl-1 {
  padding-left: 8px;
}

.pl-2 {
  padding-left: 16px;
}

.pl-3 {
  padding-left: 24px;
}

.pl-4 {
  padding-left: 32px;
}

.pr-1 {
  padding-right: 8px;
}

.pr-2 {
  padding-right: 16px;
}

.pr-3 {
  padding-right: 24px;
}

.pr-4 {
  padding-right: 32px;
}

/**************** 
  MARGIN SPACING
*****************/

.my-1 {
  margin: 8px 0px;
}

.my-2 {
  margin: 16px 0px;
}

.my-3 {
  margin: 24px 0px;
}

.my-4 {
  margin: 32px 0px;
}

.mx-1 {
  margin: 0px 8px;
}

.mx-2 {
  margin: 0px 16px;
}

.mx-3 {
  margin: 0px 24px;
}

.mx-4 {
  margin: 0px 24px;
}

.mt-1 {
  margin-top: 8px;
}

.mt-2 {
  margin-top: 16px;
}

.mt-3 {
  margin-top: 24px;
}

.mt-4 {
  margin-top: 32px;
}

.mb-1 {
  margin-bottom: 8px;
}

.mb-2 {
  margin-bottom: 16px;
}

.mb-3 {
  margin-bottom: 24px;
}

.mb-4 {
  margin-bottom: 32px;
}

.ml-1 {
  margin-left: 8px;
}

.ml-2 {
  margin-left: 16px;
}

.ml-3 {
  margin-left: 24px;
}

.ml-4 {
  margin-left: 32px;
}

.mr-1 {
  margin-right: 8px;
}

.mr-2 {
  margin-right: 16px;
}

.mr-3 {
  margin-right: 24px;
}

.mr-4 {
  margin-right: 32px;
}
