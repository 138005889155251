.copyToClipboard {
  /* width: 9gu; */
  width: 72px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.varianceCell {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 10px;
}

.pagination {
  justify-content: flex-end;
}

.header {
  display: flex;
}

.title {
  flex-grow: 1;
}

.actions {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.actions > div {
  margin-left: 8px;
}

.drawerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.drawerFooter {
  display: flex;
  align-items: center;
  padding: 24px;
  border-top: 1px solid #dedfe6;
}
.drawerFooter > div {
  flex-grow: 1;
}
.drawerFooter button {
  width: 100%;
}
.drawerFooter > div + div {
  margin-left: 8px;
}
