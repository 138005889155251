.acting-as-wrap {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 0;
  z-index: 200;
  bottom: 59px;
}

.acting-as-container {
  align-items: center;
  background: white;
  border-radius: 2px;
  border: 1px solid #bfbfca;
  display: flex;
  font-weight: 600;
  height: 46px;
  padding: 8px 16px;
}
.acting-as-container .volt-icon-container {
  width: 15px;
  height: 15px;
}

.acting-as-container .circle-x-fill {
  transition: fill 0.4s ease;
}
