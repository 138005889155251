.flex {
  display: flex;
  margin-left: auto;
}

.flexAndSpaceBetween {
  display: flex;
  justify-content: space-between;
}
.flexAndPositionTopRight {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
}

.actionButton {
  padding: 8px 16px;
}
