input[name='birth_date']::-webkit-calendar-picker-indicator {
  display: none;
}

.editableTitle {
  display: flex;
  align-items: baseline;
}

.wrappedAddress {
  white-space: pre-wrap;
}
