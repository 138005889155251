.kybTab {
  display: flex;
  flex-direction: column;
}

.kybContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
