.programContainer {
  width: 248px;
}

.amountContainer {
  width: 248px;
}

.memoContainer {
  width: 200px;
}

.alertContainer {
  width: 220px;
}

.removeContainer {
  display: none;
}

.row {
  padding: 12px 0;
}

.row:hover .removeContainer {
  display: flex;
}
