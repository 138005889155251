.emptyBlock_wrapper {
  width: 100%;
  height: 265px;

  display: flex;
  align-items: center;
  flex-direction: column;

  padding-top: 64px;
}

.iconWrapper svg {
  width: 80px;
  height: 80px;
}
