.passwordStrengthInputWrap {
  font-size: 11px;
}

.passwordStrengthTitle {
  font-weight: 600;
}

.passwordStrengthCheck {
  display: flex;
  position: relative;
  transition: color 0.2s ease;
}
.passwordStrengthCheck.vtError {
  color: #d0021b;
}

.passwordInputTooltip {
  position: absolute;
  left: 580px;
  background: white;
  padding: 20px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
  width: 300px;
  z-index: 2;
  margin-top: -102px;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.passwordInputSquare {
  z-index: 1;
  width: 20px;
  height: 20px;
  position: absolute;
  background: white;
  left: -10px;
  transform: rotate(45deg);
  box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.3);
  top: 72px;
}

.passwordInputSquareCover {
  z-index: 1;
  width: 20px;
  height: 40px;
  margin-top: -10px;
  position: absolute;
  background: white;
  left: 0px;
  top: 72px;
}

.passwordInputTooltip.vtFocused {
  opacity: 1;
}

.passwordStrengthIcon {
  position: absolute;
  margin-top: -1px;
  transform: scale(0.75);
}

.passwordStrengthIcon.iconGreen svg > path {
  fill: #65b409;
}

.passwordStrengthText {
  margin-left: 20px;
  width: 100%;
}
