.left,
.main,
.center,
.right {
  display: flex;
  align-items: center;
}

.left {
  min-width: 248px; /* left-nav width - 16px left padding */
  padding-right: 16px;
}

.main {
  justify-content: space-between;
  padding-left: 32px;
}
.mainFull {
  width: 100%;
}

.center {
  width: 100%;
  max-width: 648px;
  min-width: 300px;
}

.rightItem,
.rightIcon {
  margin-left: 16px;
}

.rightIcon {
  margin-top: 4px;
}
