.noShrink {
  flex-shrink: 0;
}

.link {
  display: flex;
  align-items: center;
}

.active .linkText {
  color: #3c33b2;
}

.linkText {
  font-size: 16px;
  letter-spacing: 1.8px;
  color: #8e8e99;
  font-weight: 600;
  transition: color 0.1s ease;
}

.link:hover .linkText {
  color: #3c33b2;
}

.underline {
  background: #3c33b2;
  position: absolute;
  transition: width 0.2s ease, left 0.2s ease, opacity 0.1s ease;
  border-radius: 1px 1px 0 0;
  margin-top: -2px;
  height: 2px;
  opacity: 1;
}

.underlineReset {
  opacity: 0;
}

.headerSub {
  background-color: white;
  padding: 0 40px;
  border-bottom: 1px solid #efeff2;
  height: 48px;
  display: flex;
  align-items: center;
  width: 100vw;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.headerSub::-webkit-scrollbar {
  display: none;
}
