.container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
  z-index: 11;
  padding-top: 5%;
}

.loadingContent {
  margin: 0 25%;
}

.contactUs {
  margin-right: -4px;
}
