.emptyState__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
}

.emptyState__wrapper > .emptyState__icon svg {
  width: 72px;
  height: 72px;
}

.emptyState__wrapper > .emptyState__subContent {
  max-width: 540px;
  text-align: center;
}
