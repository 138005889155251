.grouper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0px;
  padding: 0px;
  width: 1168px;
  height: 516px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.group {
  width: 568px;
  height: 516px;
  background: #ffffff;
  border: 1px solid #dedfe6;
  box-sizing: border-box;
  border-radius: 4px;
  flex: none;
  overflow: hidden;
}

.groupHeaderContainer {
  height: 96px;
}

.groupHeader {
  height: 48px;
  padding: 0 16px;
  background: #f8f9f9;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 1px solid #dedfe6;
}

.groupResetButton {
  font-size: 12px;
  justify-self: flex-end;
}

.groupSubheader {
  height: 48px;
  padding: 0 16px;
  border-bottom: 1px solid #dedfe6;
}

/* modifies link icon color when link is disabled */
.groupSubheaderIconDisabled svg path {
  fill: #a7a8bd;
  stroke: none !important;
}

.groupList {
  height: 420px;
  padding: 0px;
  overflow-y: scroll;
}

.groupListItem {
  flex: none;
  align-self: stretch;
  height: 40px;
  margin: 0px;
  padding: 8px 16px;
  background: #ffffff;
  cursor: pointer;

  text-align: left;
  outline: none;
  border: none;
  background: none;
}

.groupListItem:hover {
  background-color: #f8fafd;
}

.selected {
  background-color: #e9f0fe;
}
