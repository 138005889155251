.container {
  position: relative;
  min-height: 16px;
}

.edit {
  position: absolute;
  top: -4px;
  right: -5px;
}

.fixedHeight {
  height: 170px;
}
