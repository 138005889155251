/* Voltron Patches <= 1.3.2 */
.volt-dropdown-link.vt-active a {
  color: white;
}
/* /Voltron Patches */
body {
  /* font-family and color coming from normalize.css in voltron-base */
  /* set font-size to 16 to match <Text> default size */
  font-size: 16px;
  background: #f8f9f9;
}

a {
  text-decoration: none;
}

/* for <Page> component */
.active-downloads-wrap {
  z-index: 999999;
  position: fixed;
  bottom: 13px;
  right: 13px;
  border-radius: 10px;
}

.active-download-title {
  font-weight: 600;
  display: inline-block;
  border-bottom: 2px solid #06dfd9;
  margin-bottom: 5px;
  padding-bottom: 4px;
  font-size: 14px;
}

.active-download-name {
  font-size: 12px;
}

.active-download {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.active-download svg {
  animation: spinnerRotate 1.2s linear infinite;
}

@keyframes spinnerRotate {
  100% {
    transform: rotate(360deg);
  }
}

/*
  Global styles
*/
/* Element Styles */
a {
  color: inherit;
}

/* text styles */
.bold {
  font-weight: 600;
}
.light-title {
  font-size: 12px;
  font-weight: 600;
  color: #afafaf;
}

.large-and-bold {
  font-size: 18px;
  font-weight: 600;
}

.light-grey {
  color: #afafaf;
}

.grey {
  color: #868686;
}

.light-blue {
  color: #06dfd9;
}

.text-block {
  font-size: 12px;
  letter-spacing: 0.2px;
  color: #2d2d2d;
  line-height: 17px;
}

/* notification styles */
.notification-bar {
  z-index: 1000000;
}

.notification-bar.notification-bar-active.success-notification {
  background-color: #1e7f21 !important;
}

.notification-bar.notification-bar-active.error-notification {
  background-color: #8f1322 !important;
}

.notification-bar.notification-bar-active.info-notification {
  background-color: #0084ea !important;
}

.default-layout-top-content-logo {
  display: flex;
  align-items: flex-end;
}
.app-version {
  font-size: 9px;
  margin-right: 8px;
  display: flex;
  align-items: center;
}
.user-info-and-version-right-content {
  display: flex;
}

/* flex page layout */
.flex-page-layout {
  display: flex;
  width: 100%;
  position: relative;
}
.flex-left-content {
  min-height: calc(100vh - 60px);
}
.flex-main-content {
  flex-grow: 1;
}

/* global styles */
.content-block {
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.13);
  background: white;
  border-radius: 7px;
}
.shadow {
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.13);
}
