.flexDiv {
  display: flex;
  align-items: center;
}

.flexStart {
  display: flex;
  align-items: flex-start;
}

.actionContainer {
  width: 235px;
}

.actionContainer:not(:last-child) {
  margin-right: 48px;
}

.lockCardText {
  color: #3b2ce3;
  cursor: default;
}
