.labelWrapper {
  display: flex;
  align-items: center;
}

.labelColumn {
  width: 188px;
  margin-right: 8px;
  /* width: 22gu; */
}

.headerColumn {
  width: 100%;
}

.contentWrapper {
  width: 360px;
  /* width: 45gu; */
}

.tooltipWrapper {
  margin-left: 4px;
}
