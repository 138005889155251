.goToAPRContainer {
  display: flex;
  justify-content: space-between;
}

.goToAPRContainer [class*='FormError-module'] {
  white-space: nowrap;
}

.toContainer {
  margin-top: 41px;
}

.dividerLine {
  margin: 0;
  border: none;
  background-color: #dedfe6;
  height: 1px;
  max-width: 728px;
}

.formButtonsContainer {
  display: flex;
  align-items: center;
  max-width: 725px;
  justify-content: space-between;
}

.notCancelButtonsContainer {
  display: inline-flex;
}

.v1DefaultConfigsValueContainer {
  width: 500px;
}

.headingsContainer {
  display: flex;
}

.headingsLeftPart {
  flex-grow: 1;
}

.cardWithSortLabels [class*='ContentSnippet-module__hTitle'] {
  width: 180px;
  padding-right: 16px;
  box-sizing: content-box;
}
.cardWithSortLabels [class*='ContentSnippet-module__hContent'] {
  flex-grow: 1;
}

.delinquencyLabel {
  width: 50%;
}

.cardWithSortLabels [class*='CopyToClipboard-module'] > [data-testid*='volt-hspacer'] {
  width: 4px !important;
}

.pointer {
  cursor: pointer;
}

.loaderContainer {
  height: 575px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.optional {
  margin-top: 4px;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #434355;
}

.parentCPSelect [class*='Select-module__label'] > div,
.formTopSectionNotEditable h6,
.formFeesSectionNotEditable h6,
.formTopSection label span {
  color: #191919;
}

.formTopSection label span + div {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  font-family: Source Sans Pro, sans-serif;
}

.formSubtitleCP {
  width: 728px;
}

.formSubtitleAO {
  width: 748px;
}

.parentCP {
  color: #191919;
  font-weight: 600;
}

.cardModuleContentPaddingZero [class*='Card-module__content'] {
  padding: 0 !important;
}

.formButtonDropdownItem {
  display: flex;
  align-items: center;
  padding: 8px;

  width: 242px;
  height: 48px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #434355;
  line-height: 24px;

  cursor: default;
}

.formButtonDropdownItem:hover {
  background: #e9f0fe;
  color: #00041e;
}

.formButtonDropdownItem:hover * {
  color: #00041e;
}

.shortFormButtonDropdownItem {
  width: 163px;
  height: 40px;
}

.cpItemSection {
  margin-top: 8px;
}

.cpItemSection:first-child {
  margin-top: 0;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: 64px;
  padding-bottom: 64px;
}

.cp_title_link {
  font-family: 'DIN W01 Bold';
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: -0.6px;

  color: #3b2ce3;
}

.inlineFlex {
  display: inline-flex;
}

.reviewUpdatesLabel {
  font-family: Source Sans Pro;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  text-align: center;
  letter-spacing: 0.1px;

  color: #262631;

  background: #feff84;
  padding: 0 2px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  margin-top: 6px;
  margin-left: 10px;
  height: 16px;
}

.labelWithNewWrapper {
  display: flex;
  align-items: center;
}

.newLabelWrapper {
  background: #fdff84;
  height: 16px;
  padding: 0 2px;
  margin-left: 10px;
}

.newLabel {
  font-weight: 600;
  color: #262631;
}

/* rewriting drawer default width */
div[data-testid='full-token-history-drawer'] {
  width: 490px;
}

.listStyleNone {
  list-style: none;
  padding: 0;
}

.policyIcon {
  margin-bottom: 8px;
}

.textAlignCenter {
  text-align: center;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 8px;
}

.listLink {
  font-family: 'DIN W01 Bold';
  position: relative;
  letter-spacing: 0.25px;
  color: #3b2ce3;
  bottom: 1px;
  cursor: pointer;
}

table[data-testid$='-policy-list'] {
  max-width: 896px;
}

table[data-testid$='-policy-list'] > tbody > tr > td:first-child {
  padding-right: 0;
}
