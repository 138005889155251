.unnassignedRender {
  color: #9a9aac;
}

.reviewStatus {
  display: flex;
  align-items: center;
}

.reviewHeaderWrapper {
  width: 100%;
}

.reviewTableCheckbox {
  padding-left: 12px;
}
