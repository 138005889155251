.flexDiv {
  display: flex;
}

.flexDivBetween {
  display: flex;
  justify-content: space-between;
}

.flexEnd {
  display: flex;
  margin-left: auto;
}

.dropdownRow {
  width: 164px;
  padding: 12px 0 12px 12px;
  background: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}

.dropdownRow:hover {
  background: #f0f0f3;
}

.actionButton {
  padding: 8px 16px;
}
