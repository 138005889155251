.actionsWrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.actionWrap {
  display: flex;
  align-items: center;
}

.address {
  font-size: 14px;
  white-space: pre-wrap;
}

.flexDiv {
  display: flex;
  align-items: center;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.table,
.th,
.td {
  padding: 4px;
  text-align: left;
  border: 1px solid #efeff2;
}

.spaceAround {
  display: flex;
  justify-content: space-around;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

#stateInc h6 {
  width: 96px;
}

#accHoldGroupToken h6 {
  width: 106px;
}
