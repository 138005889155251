.flexDiv {
  display: flex;
  align-items: center;
}

.flexCenter {
  display: flex;
  justify-content: center;
}

.flexDivSpaceBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
}

.flexStart {
  display: flex;
  justify-content: flex-start;
}

.hidden {
  display: none;
}

.inputRightAddOnCurrencyFlag {
  display: flex;
  width: 62px;
  height: 24px;
  margin: 0 -8px;
}

.velocityControlInBetweenFields {
  margin-top: 25px;
}
