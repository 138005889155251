.notification__list {
  overflow: auto;
  height: 100%;
}

.notifications__endOfList {
  width: 173px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: #f8f9f9;
  border-radius: 16px;
}

.notificationList__item {
  padding: 16px;
}

.notificationList__item:hover {
  background: #e9f0fe;
  cursor: pointer;
}

.notificationList__item__new {
  background: #1285f0;
  width: 8px;
  height: 8px;
  border-radius: 50%;

  margin-right: 8px;

  display: inline-flex;
}

.notificationList__item__header__content {
  display: flex;
  align-items: center;
  min-height: 16px;
  line-height: 16px;

  justify-content: space-between;
  width: 398px;
}

.notificationList__item__header__headLine {
  font-weight: 600;
  display: flex;
}

.notificationList__item__header__headLine div {
  font-weight: normal;
}

.notificationList__item__header__removeBlock {
  display: none;
}

.notificationList__item:hover .notificationList__item__header__removeBlock {
  display: inline-block;
  cursor: pointer;
  position: relative;
  top: -1px;
  right: 2px;

  width: 14px;
  height: 14px;
}

/* Item header */
.notificationList__item__header {
  display: flex;
  justify-content: space-between;
}

.notificationList__item__header__content {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
}

.notificationList__item__header__bullet {
  display: inline-flex;
}

.notificationList__item__header__bullet::after {
  content: '\2022';
  height: 16px;
  line-height: 14px;
  margin: 0 2px;
}

/* Section */
.notification__section__header {
  display: flex;
  justify-content: space-between;

  padding: 16px;

  position: sticky;
  top: 0;
  width: 100%;
  background: #fff;

  z-index: 10;
}

.notification__section__content {
  list-style: none;
  padding: 0;
  margin: 0;
}

.notification__section__hr {
  height: 1px;
  background: #dedfe6;
  width: 414px;
  margin: 0 auto;
}

/* Empty state */
.emptyState__wrapper {
  height: 100%;

  display: flex;
  justify-content: center;
}

.emptyState__content {
  height: 100%;
  width: 291px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.emptyState__iconContainer {
  height: 48px;

  display: flex;
  justify-content: center;
}

.notifications__bell {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.notifications__bell__counter {
  position: absolute;
  top: -5px;
  right: -8px;
  border-radius: 50%;
  background: #108bff;
  color: white;
  width: 20px;
  height: 20px;
  text-align: center;
  font-family: 'DIN W01 Bold';
  font-size: 14px;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on, 'cpsp' on;
  box-shadow: 0px 0px 1px rgba(25, 25, 25, 0.2), 0px 0px 3px rgba(25, 25, 25, 0.12),
    0px 6px 8px rgba(25, 25, 25, 0.08);

  border: 1.5px solid #fff;
  cursor: pointer;
}

.plus {
  width: 27px;
  right: -16px;
  border-radius: 100px;
}
