.header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.headerText {
  margin-right: auto;
}

.actionDropdownContainer {
  width: 60px;
}

.dropdownHandle {
  width: 100%;
  display: flex;
}

.dropdownOption {
  min-width: 74px;
  padding: 7px;
  cursor: pointer;
}

.dropdownOption:hover {
  background-color: #e9f0fe;
}

.reviewStatus {
  padding: 4px 8px;
  border-radius: 4px;
}

.optionText {
  margin-left: 5px;
}

.selectValue {
  display: inline-block;
  margin-left: 5px;
}

.controlsContainer {
  display: flex;
}
.controlsContainerCustomer {
  flex-direction: row-reverse;
  display: flex;
}

.issuesBox {
  background: #f8f9f9;
  border-radius: 4px;
  padding: 16px;
}

.actionRowHeader {
  margin-bottom: 4px;
}

.activityLog span[class*='Activity-module__activity'] {
  text-align: left;
}

.editStatusDropdown {
  margin-left: 'auto';
}

.statusDisplay {
  margin-top: 12px;
}

.breakWords {
  overflow: hidden;
  word-break: break-all;
}
