.flexDiv {
  display: flex;
  align-items: center;
}

.controlBlock {
  border: 1px solid #efeff2;
  padding: 16px;
  border-radius: 2px;
}
