.footerLinksContainer {
  display: flex;
  list-style: none;
  text-align: center;
  padding: 0;
  justify-content: center;
  margin: 16px 0 0;
}

.footerLinksContainer li {
  display: inline-block;
  margin-right: 8px;
}

.footerLinksContainer li:not(:last-child):after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  background-color: #666679;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin-left: 8px;
}

.footerLink {
  color: #666679;
  font-size: 12px;
}
.footerLink:hover {
  color: #00041e;
  text-decoration: underline;
}
