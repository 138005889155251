.container {
  display: flex;
  align-items: center;
}

.tooltipWrapper {
  margin-left: 6px;
  position: relative;
  top: 1px;
}
