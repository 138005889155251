.actionsWrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.actionWrap {
  display: flex;
  align-items: center;
}

.address {
  font-size: 14px;
  white-space: pre-wrap;
}

.flexDiv {
  display: flex;
  align-items: center;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.table,
.th,
.td {
  padding: 4px;
  text-align: left;
  border: 1px solid #efeff2;
}

.controlBlock {
  border: 1px solid #efeff2;
  padding: 16px;
  border-radius: 2px;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

#accHoldGroupToken h6 {
  width: 106px;
}

#corpCardHolder h6 {
  width: 106px;
}
