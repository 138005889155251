.drawerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.drawerFooter {
  display: flex;
  align-items: center;
  padding: 24px;
  border-top: 1px solid #dedfe6;
}
.drawerFooter > div {
  flex-grow: 1;
}
.drawerFooter button {
  width: 100%;
}
.drawerFooter > div + div {
  margin-left: 8px;
}
