.button {
  background-color: var(--button-bg-color, #3b2ce3);
  border-color: var(--button-border-color, #3b2ce3);
  color: var(--button-fg-color, #ffffff);
  font-family: var(--button-font-family, 'DIN W01 Bold', sans-serif);
  font-size: var(--button-font-size, 1rem);
  font-weight: var(--button-font-weight, 700);
  height: var(--button-height, 2.5rem);
  padding: var(--button-padding, 0.5rem 1rem);
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  border-radius: 0.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: fit-content;
  transition: background-color 0.2s;
  will-change: background-color;
}

.button:focus {
  --button-ring-color: #b4ccfc;
  --button-ring-width: 3px;

  box-shadow: 0 0 0 var(--button-ring-width) var(--button-ring-color);
  outline: none;
}

.button:disabled {
  cursor: not-allowed;
}

.button--full-width {
  display: flex;
  width: 100%;
}

.button .content {
  align-items: center;
  display: flex;
  justify-content: center;
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s, transform 0.3s cubic-bezier(0.2, 0, 0, 1.4);
}

.button .loader {
  align-items: center;
  color: inherit;
  display: inline-flex;
  height: 100%;
  inset: 0;
  justify-content: center;
  opacity: 0;
  position: absolute;
  transform: scale(0.25);
  transform-origin: center;
  transition: opacity 0.3s, transform 0.3s cubic-bezier(0.2, 0, 0, 1.4);
  width: 100%;
}

.button--loading .content {
  opacity: 0;
  transform: scale(0.95);
}

.button--loading .loader {
  opacity: 1;
  transform: scale(1);
}

/* Primary */
.button--primary {
  --button-bg-color: #3b2ce3;
}

.button--primary:hover {
  --button-bg-color: #2100d9;
}

.button--primary:focus {
  --button-border-color: #ffffff;
}

.button--primary:disabled {
  --button-bg-color: #f0f0f3;
  --button-fg-color: #a7a8bd;
  --button-border-color: #f0f0f3;
}

/* Secondary */
.button--secondary {
  --button-bg-color: #ffffff;
  --button-fg-color: #3b2ce3;
  --button-border-color: #3b2ce3;
}

.button--secondary:hover {
  --button-bg-color: #e9f0fe;
  --button-fg-color: #b4ccfc;
}

.button--secondary:disabled {
  --button-bg-color: #ffffff;
  --button-fg-color: #a7a8bd;
  --button-border-color: #dedfe6;
}

/* Tertiary */
.button--tertiary {
  --button-bg-color: #ffffff;
  --button-fg-color: #434355;
  --button-border-color: #a7a8bd;
}

.button--tertiary:hover {
  --button-bg-color: #f0f0f3;
  --button-fg-color: #262631;
}

.button--tertiary:focus {
  --button-bg-color: #f8f9f9;
}

.button--tertiary:disabled {
  --button-bg-color: #ffffff;
  --button-fg-color: #a7a8bd;
  --button-border-color: #dedfe6;
}

/* Danger */
.button--danger {
  --button-bg-color: #b20030;
  --button-fg-color: #ffffff;
  --button-border-color: #b20030;
}

.button--danger:hover {
  --button-bg-color: #a0012c;
}

.button--danger:focus {
  --button-border-color: #ffffff;
}

.button--danger:disabled {
  --button-bg-color: #f28a97;
  --button-fg-color: #ffffff;
  --button-border-color: #f28a97;
}

/* Link */
.button--link {
  --button-font-weight: 600;
  --button-height: 1.5rem;
  --button-padding: 0.25rem;
  background-color: transparent;
  border-color: transparent;
}

.button--link.button--sm {
  --button-font-size: 0.75rem;
}

.button--link.button--md {
  --button-font-size: 1rem;
}

.button--link.button--lg {
  --button-font-size: 1.25rem;
  --button-height: 1.75rem;
}

.button--link.button--primary {
  --button-fg-color: #3b2ce3;
}

.button--link.button--primary:hover {
  --button-fg-color: #1e1672;
}

.button--link.button--primary:focus {
  --button-bg-color: #f8fafd;
  --button-fg-color: #1e1672;
}

.button--link.button--primary:disabled {
  --button-fg-color: #a7a8bd;
}

.button--link.button--secondary {
  --button-fg-color: #434355;
}

.button--link.button--secondary:hover {
  --button-fg-color: #262631;
}

.button--link.button--secondary:focus {
  --button-bg-color: #f8fafd;
  --button-fg-color: #262631;
}

.button--link.button--secondary:disabled {
  --button-fg-color: #a7a8bd;
}
