.actionsWrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.actionWrap {
  display: flex;
  align-items: center;
}

.flexDiv {
  display: flex;
  align-items: center;
}
.flexContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.pan {
  flex: none;
  order: 0;
  align-self: center;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.table,
.th,
.td {
  padding: 4px;
  text-align: left;
  border: 1px solid #efeff2;
}

.reveal {
  position: relative;
  top: 4px;
  left: 5px;
}
