.timelineContainer {
  background: #ffffff;
  width: 360px;
  padding: 16px;
  height: 720px;
  overflow: scroll;
}

.message_titleRow {
  display: flex;
}

/* AccordionNode */
.titleRow {
  display: flex;
}

.titleRow [class^='Icon-module__container'] {
  margin-right: -4px;
  transition: transform 0.2s ease;
}

.titleRow:not(.active) [class^='Icon-module__container'] {
  transform: rotate(-90deg) translateY(0px);
}
