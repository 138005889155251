.inlineFlex {
  display: inline-flex;
}

.signedUrl:hover {
  color: #532cf0;
}

.signedUrl g g {
  stroke: #8e8e99;
}

.signedUrl:hover g g {
  stroke: #532cf0;
}

.allowWrap {
  white-space: normal;
}

.customTableHeader {
  display: flex;
  height: 24px;
}
