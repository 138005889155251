.viewWrap {
  background: #ffffff;
  background-size: 800% 800%;
  height: 100vh;
  width: 100%;
  overflow: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textWrap {
  text-align: center;
  color: #434355;
}

.centered {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.additionalText {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.buttonWrap {
  display: flex;
  justify-content: center;
}
