.cardProductItem [class*='Checkbox-module__checkboxWrap'] {
  margin-right: -6px;
}

.cardProductItem [class*='Text-module__body-sm'] {
  margin-bottom: 4px;
}

.error [class*='Checkbox-module__checkbox'] {
  border-color: #ff304a;
  background-color: #ffeaed;
}

.cardProductLabel {
  text-align: initial;
}
