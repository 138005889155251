.fieldsContainer {
  display: flex;
}

.fieldContainer {
  width: calc(100% / 4 + 24px);
}

@media (max-width: 1024px) {
  .fieldsContainer {
    flex-wrap: wrap;
  }

  .fieldContainer {
    flex-basis: 50%;
    margin-bottom: 12px;
  }
}

.pointer {
  cursor: pointer;
}

.creditProductItemBorder [class*='Card-module__content'] {
  border-bottom: 1px solid #dedfe6;
  box-sizing: border-box;
  padding-top: 21px;
}

.statusWrapper {
  position: relative;
  top: -3px;
  left: -1px;
}
