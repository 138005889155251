.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.flexDiv {
  display: flex;
}

.customSpacer {
  width: 10px;
  flex-shrink: 0;
}
