.documentManager {
  min-width: 350px;
}

.dashedBox {
  border: 1px dashed #a7a8bd;
  text-align: center;
  padding: 44px;
}

.hoverBox {
  width: 100%;
  height: 424px;
  border: 1px dashed #a7a8bd;
  text-align: center;
  padding: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileBox {
  border: 1px solid #dedfe6;
  width: 100%;
  height: 424px;
  overflow: scroll;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.imageModifiers {
  position: absolute;
  top: 24px;
  right: 24px;
  display: flex;
  flex-direction: row;
}

.modifierSquare {
  width: 40px;
  height: 40px;
  background-color: white;
  border: 1px solid #a7a8bd;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.emptyImage {
  width: 160px;
  height: 160px;
  background-color: #f0f0f3;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.magicCenter {
  margin-left: auto;
  margin-right: auto;
}

.fileManager {
  display: flex;
  width: 100%;
  flex: 0 0 100%;
  flex-direction: row;
  border-bottom: 1px solid #dedfe6;
}

.fileName {
  width: 38%;
  flex: 0 0 38%;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  padding: 8px;
  border-right: 1px solid #dedfe6;
}

.fileSize {
  width: 20%;
  flex: 0 0 20%;
  text-align: right;
  overflow: hidden;
  white-space: nowrap;
  padding: 8px;
  border-right: 1px solid #dedfe6;
}

.fileDate {
  width: 36%;
  flex: 0 0 36%;
  text-align: left;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 8px;
  border-right: 1px solid #dedfe6;
}

.fileControl {
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
  display: flex;
}

.ellipsisOverflow {
  text-overflow: ellipsis;
  overflow: hidden;
}

.imageHolder {
  overflow: scroll;
  text-align: center;
  position: relative;
  width: 100%;
  height: 100%;
}

.filesPending {
  width: 100%;
  text-align: center;
  padding: 66px 0;
}
