/*
  container
    sections
      section
        sectionTitle
          caret
        sectionContent
*/

.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.eventsContainer {
  height: calc(100vh - 460px);
  overflow: scroll;
  border: 1px solid rgb(237, 237, 237);
  border-radius: 4px;
  margin: 24px 0;
}

.checkboxContainer {
  padding: 24px;
}

.sections {
  width: 100%;
}

.hideSection {
  display: none;
}

.section:last-child {
  margin-bottom: 0 !important;
}

.sectionTitle {
  align-items: center;
  cursor: pointer;
  display: flex;
  line-height: 24px;
  transition: color 0.1s ease;
}

.sectionTitle:hover {
  color: #8f8fa2;
}

.sectionTitle.isExpanded {
  color: #5f5f7a;
}

.sectionTitle.isExpanded:hover {
  color: #8f8fa2;
}

.sectionTitle .text {
  flex: 1;
}

.caret {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition-property: color, transform;
  transition: all 0.2s ease;
  z-index: 0;
}

.caret.isExpanded {
  transform: rotate(90deg);
}

/* If section title is hovered over, also apply hover color to icon */
/* This is the color that is applied when the icon is hovered over by default */
.sectionTitle:hover .caret [class*='container'] [class*='voltIconStroke'] {
  stroke: #8f8fa2;
}

.sectionContent {
  color: #434355;
  display: none;
  font-size: 16px;
  line-height: 24px;
  margin-left: 28px;
  margin-top: 16px !important;
  max-height: 0;
  transition: max-height 0.35s ease-in-out;
}

.sectionContent.isExpanded {
  display: flex;
  /* Arbitrarily large max height */
  max-height: 10000px;
}

.sectionContent.isExpanded.isColumn {
  flex-direction: column;
}

/* Search */

.searchContainer {
  height: 40px;
  width: 100%;
  background-color: #f8f9fa;
  position: relative;
  position: sticky;
  border-bottom: 1px solid rgb(237, 237, 237);
  top: 0;
  z-index: 99;
}

.searchInput {
  height: 100%;
  width: 100%;
  border: none;
  background-color: white;
  padding-left: 34px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  line-height: 24px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.searchInput:focus {
  outline: none;
}

.searchIcon {
  position: absolute;
  top: 13px;
  left: 9px;
}

.cancelSearchIcon {
  position: absolute;
  top: 13px;
  right: 9px;
  cursor: pointer;
}
