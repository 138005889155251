.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.content {
  padding-left: 24px;
}

.key {
  display: inline-flex;
  justify-content: flex-end;
  width: 64px;
  margin-right: 8px;
}
