.hr {
  margin-left: 16px;
}

.noControls {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.iconParent {
  height: 72px;
  width: 72px;
  margin-top: 57px;
}

.balancer {
  position: relative;
  top: 24px;
  left: 24px;
}
