.diva-api-download-controls-wrap {
  position: relative;
}
.diva-api-download-controls-container {
  background: white;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.25);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s ease;
  padding: 30px;
  position: absolute;
  z-index: 102;
  margin-bottom: 40px;
  right: 0;
  min-width: 150px;
  width: max-content;
}

.diva-api-download-controls-container.vt-active {
  pointer-events: inherit;
  opacity: 1;
}

.download-controls-title {
  margin-bottom: 8px;
}
.download-controls-file-preview-text {
  font-weight: bold;
}
.diva-api-download-controls-control:hover .svg-fill {
  fill: white;
}
.diva-api-download-controls-control:hover .svg-stroke {
  stroke: white;
}
.download-controls-title {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}
.download-controls-top-block {
  display: flex;
  justify-content: center;
}

.diva-api-download-controls-wrap .volt-button:hover .svg-stroke {
  stroke: white;
}

.download-selector-block {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.report-loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.report-loading-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 50%;
}
