.verticalRule {
  width: 1px;
  height: 80px;
  /* height: 10gu; */
  border: 1px solid #dedfe6;
  margin-top: -10px;
}

.tooltipWrapper {
  margin-left: 4px;
}
