.cardWrap {
  width: 100%;
  border-radius: 2px;
  background-color: white;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.content {
  position: relative;
  padding: 16px 24px 0 0;
}

.actionDropdown {
  position: absolute;
  top: 8px;
  right: 16px;
}

.footer {
  border-top: 1px solid #ecebf7;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
