a.token {
  width: 140px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  text-align: left;
}

.leftLoader {
  margin-right: auto;
}
