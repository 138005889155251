.header {
  display: flex;
  justify-content: space-between;
}

.approvalStatus {
  cursor: pointer;
  display: flex;
}

.statusContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.approvalStatus [class^='Icon-module__container'] {
  transition: transform 0.2s ease;
}

.approvalStatus:not(.active) [class^='Icon-module__container'] {
  transform: rotate(-90deg) translateY(0px);
}

.statusIcon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
