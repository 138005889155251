.cardIcon,
.networkIcon {
  margin-right: 8px;
  display: inline-flex;
  vertical-align: middle;
}
.cardIcon svg path {
  fill: currentColor;
}

.cardLinkContainer {
  cursor: pointer;
}
