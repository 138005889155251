.flex {
  display: flex;
  /* width: 100%; */
}

/* 
  flex-direction
*/
.flexDirectionRow {
  flex-direction: row;
}

.flexDirectionColumn {
  flex-direction: column;
}

/* 
  justify-content
*/
.justifyContentCenter {
  justify-content: center;
}

.justifyContentFlexStart {
  justify-content: flex-start;
}

.justifyContentFlexEnd {
  justify-content: flex-end;
}

.justifyContentSpaceBetween {
  justify-content: space-between;
}

/* 
  align-items
*/
.alignItemsCenter {
  align-items: center;
}

.alignItemsFlexStart {
  align-items: flex-start;
}

.alignItemsFlexEnd {
  align-items: flex-end;
}

.alignItemsSpaceBetween {
  align-items: space-between;
}
