.actionsHeaderWrapper {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  padding: 0 1px 1px 0;
  border-left: 1px solid #dedfe6;
  background-color: #fff;
  background-clip: content-box;
}

.actionsHeaderWrapper::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  width: 4px;
  background: linear-gradient(
    90deg,
    rgba(239, 239, 242, 0) 18.75%,
    rgba(240, 240, 243, 0) 18.76%,
    #f0f0f3 100%
  );
}
