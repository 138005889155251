.cardContainer {
  padding: 16px;
  background: #ffffff;
}

.cardTitle {
  display: flex;
  justify-content: space-between;
}

.cardTitle [class^='Icon-module__container'] {
  margin-right: -4px;
  transition: transform 0.2s ease;
}

.cardTitle:not(.active) [class^='Icon-module__container'] {
  transform: rotate(-90deg) translateY(0px);
}

.progressBarContainer {
  display: flex;
  align-items: center;
}

.rows {
  border-top: 1px solid #d7d7da;
  background: #ffffff;
}

.editIcon {
  float: right;
  padding-right: 24px;
  padding-top: 24px;
}

.content {
  padding: 24px;
}

.footerButtons {
  padding: 0 16px 16px 0;
  display: flex;
  justify-content: flex-end;
}

.iconContainer {
  border: 1px solid #d7d7da;
  box-sizing: border-box;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6px;
  position: relative;
}

.iconContainer:focus,
.iconContainer:hover {
  border-color: #3b2ce3;
}

.editActive {
  display: flex;
  align-items: stretch;
}

.editActive [class^='Select-module__wrap'] {
  width: 100%;
}

.editSubActive {
  width: calc(100% - 36px);
}

.notEditActive {
  display: flex;
  align-items: center;
}

.statusContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.statusIcon {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.header {
  cursor: pointer;
  text-transform: capitalize;
}

.boolRow {
  display: flex;
  align-items: center;
}
