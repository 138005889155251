.container {
  width: 224px;
  min-height: 144px;
}

.lockCardText {
  color: #3b2ce3;
  cursor: default;
}

.lockCard_toggleWrapper {
  padding-top: 4px;
}

.lockCard_toggleWrapper a {
  margin-left: 1px;
}

.lockCardText:hover {
  color: #3b2ce3;
}
