.noAccountsBlock {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: -0.5px;

  color: #00041e;
  padding-top: 24px;
}

.filterIndicator {
  width: 20px;
  height: 20px;

  margin-left: 8px;

  background: #2100d9;
  border-radius: 4px;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  padding-top: 8px;
}
