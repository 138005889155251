.container {
  padding: 32px 24px 32px 32px;
  width: 1000px;
}

.close {
  position: absolute;
  right: 32px;
  top: 32px;
}

.lineBreak {
  white-space: normal;
}
