.iconHighlight {
  background: #3c33b2;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.actions {
  display: flex;
  align-items: center;
  height: 24px;
  background-color: white;
}

.action {
  cursor: pointer;
  height: 42px;
  display: flex;
  padding: 8px 16px;
  font-size: 14px;
  background-color: #fff;
  transition: background-color 0.1s ease;
  width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  align-items: center;
  box-sizing: border-box;
}

.action:hover {
  background-color: #f8f9fa;
}

.view {
  height: 22px;
  font-size: 12px;
  border: 1px solid #bfbfca;
  line-height: 22px;
  padding: 0 8px;
  border-radius: 2px 0 0 2px;
  transition: all 0.1s ease;
  transition-property: background-color border;
  cursor: pointer;
}

.view:hover {
  background-color: #ecebf7;
  border: 1px solid #3c33b2;
  z-index: 1;
  color: #3c33b2;
}

.actionsControl {
  border: 1px solid #bfbfca;
  transition: all 0.1s ease;
  transition-property: background-color border;
  height: 22px;
  border-radius: 0 2px 2px 0;
  margin-left: -1px;
  cursor: pointer;
}

.actionsControl:hover,
:global .vt-active :local .actionsControl {
  background-color: #ecebf7;
  border: 1px solid #3c33b2;
  z-index: 1;
}

.actionsControl:hover :local polyline,
:global .vt-active :local .actionsControl polyline {
  stroke: #3c33b2;
}

.actionsControl div[class*='container'] {
  margin-bottom: -3px;
}
